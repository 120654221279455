'use strict';

AOS.init({
    startEvent: 'DOMContentLoaded',
    delay: 200,
    offset: 80
});

const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
}

const openModal = (modal) => {
    const body = document.querySelector('body');
    const padRight = getScrollbarWidth();

    modal.style.display = 'block';
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${padRight}px`;

    setTimeout(() => {
        modal.classList.add('modal--opened');
    }, 0);
}

const closeModal = (modal) => {
    const body = document.querySelector('body');

    modal.classList.remove('modal--opened');

    setTimeout(() => {
        modal.style.display = 'none';
        body.style.overflow = '';
        body.style.paddingRight = '0';
    }, 200);
}

const checkIsPageScrolled = () => {
    const scrollPosition = window.scrollY;
    const backBtn = document.querySelector('.back-top');

    if (scrollPosition > 300 && !backBtn.classList.contains('back-top--active')) {
        backBtn.classList.add('back-top--active')
    }

    if (scrollPosition <= 300 && backBtn.classList.contains('back-top--active')) {
        backBtn.classList.remove('back-top--active');
    }
}

const sendRequest = (method, url, body = null) => {
    const options = {
        method,
        body,
        headers: {
            Accept: 'application/json',
        }
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(async (response) => {
                const result = await response.json();

                if (response.ok) {
                    resolve(result);
                }

                reject(result);
            }).catch(reject);
    });
}

const setErrors = (form, errors) => {
    const inputs = form.querySelectorAll('.modal__input');

    inputs.forEach((input) => {
        const name = input.getAttribute('name');
        const errorMessage = errors?.[name];

        if (!!errorMessage) {
            const field = input.closest('.modal__field');
            const errorBlock = field.querySelector('.modal__error');

            field.classList.add('modal__field--with-error');
            errorBlock.innerHTML = errorMessage.join(', ');
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    // scroll to page start
    const backBtn = document.querySelector('.back-top');

    window.addEventListener('load', checkIsPageScrolled);
    window.addEventListener('scroll', checkIsPageScrolled);
    backBtn.addEventListener('click', (e) => {
        e.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    });

    // login modal
    const accountBtn = document.querySelector('.js-openModal');

    if (accountBtn) {
        accountBtn.addEventListener('click', (e) => {
            e.preventDefault();
            const modal = document.querySelector('.modal');

            openModal(modal);
        });
    }

    window.addEventListener('click', (e) => {
        const { target } = e;
        if (target.classList.contains('modal__dialog')
            || target.classList.contains('js-closeModal')
            || target.closest('.js-closeModal')) {
            closeModal(document.querySelector('.modal'));
        }
    });

    // opportunities slider
    const possibilitiesSlider = new Swiper('.js-containsSlider', {
        slidesToShow: 1,
        spaceBetween: 20,
        speed: 600,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    })

    possibilitiesSlider.on('slideChange', function () {
        const activeSlide = possibilitiesSlider.realIndex;
        const activeTile = document.querySelector('.proboost-contains__tile--active');
        const tiles = document.querySelectorAll('.proboost-contains__tile');

        activeTile.classList.remove('proboost-contains__tile--active');
        tiles[activeSlide].classList.add('proboost-contains__tile--active');
    });

    const possibilitiesTiles = document.querySelectorAll('.proboost-contains__tile');

    possibilitiesTiles.forEach((item, index) => item.addEventListener('click', (e) => {
        e.preventDefault();
        possibilitiesSlider.slideTo(index);
    }));

    // student interface slider
    const courseSlider = new Swiper('.js-courseSlider', {
        slidesToShow: 1,
        spaceBetween: 20,
        speed: 600,
        autoplay: {
            delay: 5000,
        },
    });

    courseSlider.on('slideChange', function () {
        const activeSlide = courseSlider.realIndex;
        const activeLink = document.querySelector('.student-interface__item--active');
        const links = document.querySelectorAll('.student-interface__item');

        activeLink.classList.remove('student-interface__item--active');
        links[activeSlide].classList.add('student-interface__item--active');
    });

    const coursesLinks = document.querySelectorAll('.student-interface__item');

    coursesLinks.forEach((link, index) => link.addEventListener('click', (e) => {
        e.preventDefault();

        courseSlider.slideTo(index);
    }));

    // auth form
    const authForm = document.querySelector('.js-authForm');
    const formInputs = document.querySelectorAll('.modal__input');

    formInputs.forEach((input) => {
        input.addEventListener('focus', () => {
            const field = input.closest('.modal__field');

            field.classList.remove('modal__field--with-error');
        });
    })

    authForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const { target } = e;
        const data = new FormData(target);

        sendRequest('POST', '/backend/auth/login', data)
            .then((response) => {
                const { schools } = response.data;
                const domain = window.location.host;
                const schoolSubdomain = schools[0].subdomain;

                window.location.replace(`https://${schoolSubdomain}.${domain}`);
            })
            .catch((error) => {
                setErrors(authForm, error.errors);
            });
    });
});
